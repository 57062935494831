.fallback-spinner {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $white;

  &.app-loader {
    height: 100vh;
    flex-direction: column;

    .loading {
      margin-top: 1rem;
    }
  }

  .loading {
    width: 55px;
    height: 55px;
    border-radius: 50%;
    border: 3px solid transparent;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;

    .effect-1,
    .effect-2,
    .effect-3 {
      width: 55px;
      height: 55px;
      border-radius: 50%;
      border: 3px solid transparent;
      border-left: 3px solid rgba(#A02A23, 1);
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
    }

    .effect-1 {
      position: absolute;
      animation: rotate 1s ease infinite;
    }

    .effect-2 {
      position: absolute;
      animation: rotateOpacity 1s ease infinite 0.1s;
    }

    .effect-3 {
      -webkit-animation: rotateOpacity 1s ease infinite 0.2s;
      animation: rotateOpacity 1s ease infinite 0.2s;
    }

    .loading .effects {
      transition: all 0.3s ease;
    }
  }
}

.background {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, .2);
  z-index: 1000;
}

.background-relative {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  z-index: 1;
  background-color: rgba(0, 0, 0, .2);
}

.fixed {
  position: fixed;
  z-index: 1000;
  width: 100%;
  top: 0;
  left: 0;
}

.loading-body {
  overflow: hidden;
  height: 100vh;
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

@keyframes rotateOpacity {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    opacity: 0.1;
  }

  100% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
    opacity: 1;
  }
}