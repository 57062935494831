/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */
@import '../../@core/scss/base/bootstrap-extended/variables';

/*
*	INDEX
* 1. SPACEMENTS
* 2. BUTTONS
* 3. TEXTS
* 4. CONTAINERS/SECTIONS
* 5. COMPONENTS
*/

// 1. SPACEMENTS
.mb-30 {
	margin-bottom: 30px;
}

.mb-32 {
	margin-bottom: 2rem;
}

.mt-32 {
	margin-top: 2rem;
}

.mb-40 {
	margin-bottom: 40px;
}

.mb-76 {
	margin-bottom: 76px;
}

.mt-32 {
	margin-top: 32px;
}

// 2. BUTTONS

.btn-link {
	background: none;
	border: none;

	&:hover {
		text-decoration: underline;
	}
}

.btn-form-size {
	max-width: 190px;
	width: 100%;
}

.btn-default-size {
	max-width: 144px;
	width: 100%;
}

.btn-lg {
	height: 47px;
	font-size: 14px;
}

.text-btn {
	border: none;
	background: none;
	transition: .2s;

	&:hover {
		opacity: .6;
	}
}

.btn-flat-success {
	background-color: rgba($success, 0.12);

	transition: .2s;

	&:hover {
		background-color: transparent !important;
	}
}

@media(max-width: 991px) {
	.btn-mobile {
		display: block;
		max-width: 100%;
		width: 100%;
	}
}

// 3. TEXTS
.text-headings {
	color: $headings-color;
}

.text-body-color {
	color: $body-color;
}

.text-theme-light {
	color: $gray-100;
}
.text-overflow {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

// 4. CONTAINERS/SECTIONS

.theme-container {
	padding: 0 2.25rem;
    padding-top: 48px;
}

.default-section-pt {
    padding-top: 40px;
}

.white-content {
	background-color: $white;
}

.full-screen {
	height: calc(100vh - 68px);
}

.default-scroll-container {


    // Firefox scrollbar
    scrollbar-width: thin;
    scrollbar-color: $text-muted transparent;
	
    &::-webkit-scrollbar {
        width: 4px;
    }
    
    &::-webkit-scrollbar-track {
        background: transparent;
        border-radius: 10px;
    }
    
    &::-webkit-scrollbar-thumb {
        background: $text-muted;
        border-radius: 10px;
    }
}

.datatable-btns {
	.btn-icon {
		padding: 0;

		&:hover, &:focus, &:active {
			background: none !important;
			color: $primary;
		}
	}
}

@media(max-width: 991px) {
	.full-screen {
		height: unset;
	}
	.theme-container {
		padding: 0;
	}
}

// 5. COMPONENTS

.img-cover {
	object-fit: cover;
}

.card {
	box-shadow: none;
	border-radius: 16px;
}

.default-filter-container {
	background-color: $table-head-bg;
	border: 1px solid $border-color;
	border-radius: 16px;

	&.grouped {
		border-radius: 16px 16px 0px 0px;
		border-bottom: none;
	}

	.default-filter-body {
		padding: 24px 32px 32px 32px;
	}

	.default-filter-footer {
		border-top: 1px solid $border-color;
		padding: 14px 32px;
		
		p {
			text-transform: uppercase;
			font-weight: 400;
			font-size: 12px;
			line-height: 18px;
			color: $headings-color;
			margin: 0;
		}
	}
}

.form-card {
	box-shadow: none;
	border: 1px solid $border-color;
	border-radius: 10px;

	.card-body {
		padding: 40px;
		padding-bottom: 50px;
	}

}

.search-form {
	label {
		font-size: 14px;
	}
	input {
		min-height: 30px;
		height: 30px;
	}

	.transparent-input {
		color: $text-muted !important;
		font-size: 10px !important;
		
		&:focus {
			box-shadow: none;
		}
	}
}

.swal2-title {
	margin: 0 !important;
	padding: 0 32px !important;
	margin-bottom: 6px !important;
	color: $headings-color !important;
	font-size: 21px !important;
	font-weight: 500 !important;
}

.swal2-html-container {
	margin: 0 !important;
	padding: 0 32px !important;
	margin-bottom: 4px !important;
	color: $body-color !important;
	font-size: 14px !important;
}

.swal2-icon.swal2-warning {
	border-color: $warning !important;
    color: $warning !important;
	margin-bottom: 18px !important;
}

.swal2-actions {
	flex-direction: row-reverse;
}


.default-dropzone {
	transition: .2s;

	&.no-click-dropzone {
		cursor: default !important;
	}

	&.dropzone {
		border-radius: 0;
		border-color: $primary;
		border-width: 2px;
		background: transparent;

		h4 {
			font-weight: 500;
			font-size: 18px;
			color: $primary;
			margin-bottom: 8px;
			text-align: center;
		}
		p {
			font-weight: 400;
			font-size: 14px;
			text-align: center;
			color: $primary;
			margin: 0;
		}
	}

	&.focused {
		background: rgba($primary, 0.12);
		transition: .2s;
	}
}

.password-mask {
	width: 8px;
	height: 8px;
	border-radius: 4px;
	background: $body-color;
	margin: 0 2px;
}


.selected-files-container {
	padding: 32px 0;

    .file-icon {
        background-color: $body-bg;
        border-radius: 4px;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 16px;
    }

    p {
        font-weight: 400;
        font-size: 12px;
        color: $body-color;
        margin: 0;
    }
}

.file-link {
	background: rgba(115, 103, 240, 0.12);
	padding: 8px 16px;
}

.input-group-disabled {
	.input-group-text {
		background-color: $input-disabled-bg;
	}
}

.mw-unset {
	max-width: unset !important;
	width: unset !important;
}